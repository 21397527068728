/**
 * Audio player
 */

export default (() => {
  const player = document.querySelectorAll('.audio-player')

  if (player.length === 0) return

  for (let i = 0; i < player.length; i++) {
    const playerContainer = player[i],
      audio = playerContainer.querySelector('audio'),
      playButton = playerContainer.querySelector('.ap-play-button'),
      seekSlider = playerContainer.querySelector('.ap-seek-slider')

    let raf = null

    // Start / stop audio
    playButton.addEventListener('click', () => {
      if (playButton.classList.contains('ap-pause') === false) {
        play()
      } else {
        pause()
      }
    })

    const pause = () => {
      playButton.classList.remove('ap-pause')
      audio.pause()
      cancelAnimationFrame(raf)
    }

    const play = () => {
      playButton.classList.add('ap-pause')
      audio.play()
      requestAnimationFrame(whilePlaying)
    }

    // Instantiate sliders: Seek slider + Volume slider
    const showRangeProgress = (rangeInput) => {
      if (rangeInput === seekSlider)
        playerContainer.style.setProperty(
          '--seek-before-width',
          (rangeInput.value / rangeInput.max) * 100 + '%'
        )
      else
        playerContainer.style.setProperty(
          '--volume-before-width',
          (rangeInput.value / rangeInput.max) * 100 + '%'
        )
    }

    seekSlider.addEventListener('input', (e) => {
      showRangeProgress(e.target)
    })

    const setSliderMax = () => {
      seekSlider.max = Math.floor(audio.duration)
    }

    const displayBufferedAmount = () => {
      if (audio.buffered.length > 0) {
        const bufferedAmount = Math.floor(
          audio.buffered.end(audio.buffered.length - 1)
        )
        playerContainer.style.setProperty(
          '--buffered-width',
          `${(bufferedAmount / seekSlider.max) * 100}%`
        )
      }
    }

    const whilePlaying = () => {
      seekSlider.value = Math.floor(audio.currentTime)
      let val = (seekSlider.value / seekSlider.max) * 100
      playerContainer.style.setProperty('--seek-before-width', `${val}%`)
      raf = requestAnimationFrame(whilePlaying)
      if (val === 100) {
        pause()
        seekSlider.value = 0
        audio.currentTime = 0
        displayBufferedAmount()
      }
    }

    if (audio.readyState > 0) {
      setSliderMax()
      displayBufferedAmount()
    } else {
      audio.addEventListener('loadedmetadata', () => {
        setSliderMax()
        displayBufferedAmount()
      })
    }

    audio.addEventListener('progress', displayBufferedAmount)

    seekSlider.addEventListener('input', () => {
      if (!audio.paused) {
        cancelAnimationFrame(raf)
      }
    })

    seekSlider.addEventListener('change', () => {
      audio.currentTime = seekSlider.value
      if (!audio.paused) {
        requestAnimationFrame(whilePlaying)
      }
    })
  }
})()
